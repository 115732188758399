import { fromZonedTime as zonedTimeToDate } from 'date-fns-tz';

// types
import { OrderFormProps, OrderProductInfo } from 'utils/types/orders';
import { Instrument } from 'utils/types';
import { ORDER_IN_KIND } from 'utils/constants';

function getCloseTime(
  cutoffs: Instrument['cutoffs'],
  timezone?: OrderProductInfo['timezone'],
  deliveryType?: OrderFormProps['deliveryType']
) {
  const cutOffTimes: { [index: string]: string | undefined } = {
    IN_KIND: cutoffs?.inKindCutOffTime,
    CASH: cutoffs?.cashCutOffTime,
  };
  const cutOff = cutOffTimes[deliveryType || ORDER_IN_KIND];

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const formatter = new Intl.DateTimeFormat([], options);

  return cutOff && timezone
    ? zonedTimeToDate(new Date(`${formatter.format(new Date())} ${cutOff}`), timezone)
    : new Date();
}

export default getCloseTime;
